import React, {useState} from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import Showcase from "../components/showcase"

var showcaseActive = false;

const artwork_row = (artworks) => {

      let artwork_rows = artworks.reduce((resultArray, item, index) => { 
    

          
    const chunkIndex = Math.floor(index/3)

    if(!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push({
    type:"image", 
    link:"/" + item.slug,
    url:item.imageThumbnail?.[0]?.medium ?? item.images?.[0]?.small,
    details:<div className={item.sold}><div className="title">{item.artist[0].title}</div><div className="materials">{item.title}</div><div className="dimensions">{item.materials}
    </div></div>
    })

    return resultArray
    }, []).map((row) => <ImageRow  forceLength={3}  images={row} /> )
    
    return artwork_rows;
}
const renderDescription = (showcase) => {
  if(showcase) {
    return <div style={{textAlign:"center", margin:"20px"}}>A representative selection of artworks sold or no longer available</div>
  }
}

const renderShowcaseButton = (showcase, setShowcaseActive,showcaseActive) => {
  if(showcase) {
    return <h3 className={showcaseActive ? "active" : null} onClick={() => {setShowcaseActive(true)}}>Artist showcase</h3>
  }
}

const renderArtworksButton = (artworks, setShowcaseActive,showcaseActive) => {
  if(artworks) {
    return <h3 className={!showcaseActive ? "active" : null} onClick={() => {setShowcaseActive(false)}}>Available Artworks</h3>
  }
}
const renderStatementButton = (visible,active,setBottomText) => {
  if(visible) {
  return <h3 className={active ? "active" : null} onClick={() => {setBottomText("artistStatement")}}>Artist statement</h3>
  }
}

const renderBioButton = (visible,active,setBottomText) => {
  if(visible) {
  return <h3 className={active ? "active" : null} onClick={() => {setBottomText("biography")}}>Bio</h3>
  }
}


const renderCVButton = (cv) => {
  if(Array.isArray(cv) && cv[0] && cv[0].filename) {
  return <Link to={"https://franceskeevil.frb.io/cv/" + cv[0].filename}><h3>Artist CV (pdf)</h3></Link>
  }
}

const renderWebsiteButton = (website) => {
  if(website) {
  return <Link to={website}><h3>Artist website</h3></Link>
  }
}

const renderAdditionalDocuments = (additionalDocuments) => {
  let documents = additionalDocuments.map((document) => <Link to={document.url}><h3>{document.title}</h3></Link>)
  return documents.length > 0 ? <><Titles titles={{"h2":"Additional Documents"}} style={{marginRight:"0px", textAlign:"center"}} /><div style={{textAlign:"center"}} className={"titles inline"}>{documents}</div></> : null
}
const renderText = (current,artist) => {
  let text = artist[current];
  if(text) {
  return <ImageRow rowType={"largeRow"} 
    images={
      [{type:"text", text: <div><p style={{padding:"36px 20px", fontSize:"18px", whiteSpace: "pre-wrap",marginLeft:"auto",marginRight:"auto",maxWidth:"1000px" }}>{text}</p></div>, span:"12"}] } />
    }
}
const IndexPage = ({pageContext}) => {

  const artworksVisible = pageContext.artist.artworks && pageContext.artist.artworks.length > 0
  const startShowcase = !(artworksVisible) && pageContext.artist.showcaseArtworks && pageContext.artist.showcaseArtworks.length > 0; 
  const [showcaseActive, setShowcaseActive] = useState(startShowcase);
  const [bottomText, setBottomText] = useState("artistStatement");
  let artworks;
  
  console.log(pageContext.artist.biography);
  if(showcaseActive) {
    artworks = pageContext.artist ? pageContext.artist.showcaseArtworks : [];
  }
  else {
    artworks = pageContext.artist ? pageContext.artist.artworks : [];
  }
  if(pageContext.artist) {

  return (<div>
  <Layout title={pageContext.artist.title} hero={<Carousel images={pageContext.artist.images.map(image => image.url)}   />}>
    <SEO title={pageContext.artist.title} />

    <div >
      <h1 style={{marginBottom:"100px", fontSize:"36px",marginTop:"60px", textAlign:"center",}}>{pageContext.artist.title}</h1>
      <div className={"titles inline"}>
      {renderStatementButton(pageContext.artist.artistStatement, bottomText == "artistStatement", setBottomText)}
      {renderBioButton(pageContext.artist.biography, bottomText == "biography", setBottomText)}
      {renderCVButton(pageContext.artist.artistCv)}
      {renderWebsiteButton(pageContext.artist.website)}
      {renderText(bottomText,pageContext.artist)}
    {renderAdditionalDocuments(pageContext.artist.additionalDocuments)}
      </div>
        <div className={"titles inline"}>
          {renderArtworksButton(artworksVisible, setShowcaseActive, showcaseActive)}
          
          {renderShowcaseButton(pageContext.artist.showcaseArtworks.length > 0, setShowcaseActive, showcaseActive)}
        </div>
      
      {renderDescription(showcaseActive)}
      {artwork_row(artworks)}


  </div>

  </Layout>
</div>)
}
return <div></div>
}

export default IndexPage
